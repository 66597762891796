<template>
  <div class="listContainer">
    <!-- <div class="dl">
            <div style="margin-right:10px">Proctor Pro</div>
            <img src="../../../assets/dl.png" style="width:100px;" />
        </div> -->
    <div v-for="(item, index) in event_rooms" :key="index" class="eventItem">
      <img
        :src="
          require('../../../assets/banner/' +
            handleEventName(item.event_name) +
            '.png')
        "
        class="eventImg"
      />
      <div class="left">
        <div
          class="itemTitle"
          :style="{ color: colorMap[handleEventName(item.event_name)] }"
        >
          {{ handleEventName(item.event_name) }}
          {{
            project_code == "22-23AM_International"
              ? "International"
              : "Continental"
          }}
        </div>
        <div
          class="itemName"
          :style="{ color: colorMap[handleEventName(item.event_name)] }"
        >
          {{ item.name }}
        </div>
        <div class="eventTime">
          <div class="eventTimeContainer">
            <!-- <div class="eventTimeItem">
              Lecture Entry：{{ formateTime(item.event_start_time) }}
            </div> -->
            <div v-if="item.exam_info" class="eventTimeItem">
              Test Opens (UTC) : {{ formateTime(item.exam_info.event_start_time) }}
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <div
          class="eventStatusContainer"
          :style="{ background: colorMap[handleEventName(item.event_name)] }"
        >
          <div class="toContent" @click="toRoom(item)">Lecture Entry</div> -->
          <!-- <div class="outContent" v-if="item.status.status==='还未开始'" @click="toRoom1(item)">
                        <div class="txt">Lecture Countdown</div>
                        <div class="time" :style="{'color':colorMap[handleEventName(item.event_name)]}">
                            {{item.countDownTimes?item.countDownTimes:getFormatDuringTime(0)}}
                        </div>
                    </div>
                    <div class="outContent" v-if="item.status.status==='已结束'">
                        <div class="txt">Finished</div>
                        <div class="time" :style="{'color':colorMap[handleEventName(item.event_name)]}">
                            {{getFormatDuringTime(0)}}
                        </div>
                    </div> -->
        <!-- </div> -->
        <div
          v-if="item.exam_info"
          class="eventStatusContainer"
          :style="{ background: colorMap[handleEventName(item.event_name)] }"
        >
          <div
            class="toContent"
            v-if="item.exam_info.status.status === '进行中'"
            @click="toExam(item)"
          >
            Test Entry
          </div>
          <div
            class="outContent"
            v-if="item.exam_info.status.status === '还未开始'"
          >
            <div class="txt">Test Countdown</div>
            <div
              class="time"
              :style="{ color: colorMap[handleEventName(item.event_name)] }"
            >
              {{
                item.exam_info.countDownTimes
                  ? item.exam_info.countDownTimes
                  : getFormatDuringTime(0)
              }}
            </div>
          </div>
          <!-- <div
            class="outContent"
            v-if="item.exam_info.status.status === '已结束'"
          >
            <div class="txt">Finished</div>
            <div
              class="time"
              :style="{ color: colorMap[handleEventName(item.event_name)] }"
            >
              {{ getFormatDuringTime(0) }}
            </div>
          </div> -->

          <div
            class="toContent"
            v-if="item.exam_info.status.status === '已结束'"
            @click="showTip(2)"
          >
            Closed for Entry
          </div>
          

          <div
            class="toContent"
            v-if="item.exam_info.status.status === '已完成'"
            @click="showTip(1)"
          >
            Completed
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMyZhiboRooms,
  checkTestLinkOpen,
  checkZhiboOpen,
  checkZhiboOpenQian,
  getExerciseTestTemplate,
  checkIsUploadTest,
  checkUserInvigilate,
} from "../../../api/index";
import {
  getUserId,
  getProjectCode,
  setTestEvent,
  setSessionUrl,
  setSessionName,
} from "../../../utils/store";
export default {
  data() {
    return {
      project_code: getProjectCode(),
      event_rooms: [],
      colorMap: {
        Mock: "#11448D",
        Artithon: "#11448D",
        Biothon: "#246EB3",
        Chemithon: "#445CB4",
        Econthon: "#E3A948",
        Geothon: "rgb(4,52,124)",
        Historithon: "rgb(171,9,61)",
        Mathethon: "rgb(9,54,80)",
        Physithon: "rgb(53,110,174)",
        Psychothon: "rgb(111,183,217)",
      },
      user_id: getUserId(),
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getMyZhiboRooms(getUserId(), getProjectCode()).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.event_rooms = res.data.data.event_rooms.map((item) => {
            if (item.status.status === "还未开始") {
              this.handleRestTime(item);
            }
            if (item.exam_info && item.exam_info.status.status === "还未开始") {
              this.handleRestTime(item.exam_info);
            }
            return item;
          });
        }
      });
    },

    toRoom(item) {
      console.log(2222, item);

      checkZhiboOpen(
        getUserId(),
        item.event_meeting_room_ids[0],
        getProjectCode(),
        item.event_name
      ).then((res) => {
        if (res.data.code === 0) {
          if (item.huifang && item.huifang == "是") {
            console.log(11111, item);

            setSessionUrl(item.videoUrl);
            setSessionName(item.name);

            //判断是否需要监考
            checkUserInvigilate(getProjectCode(), getUserId()).then((res) => {
              // console.log(1111,res)
              //true 需要监考
              if (res.data == true) {
                this.$router.push({
                  path: "/ready/lecture",
                  query: {
                    videoTime: item.videoTime,
                    template_id: item.template_id,
                    type: "video",
                  },
                });
              } else {
                let url = this.$router.resolve({
                  path: "VideoPlay",
                  query: {
                    videoTime: item.videoTime,
                    template_id: item.template_id,
                  },
                });
                window.open(url.href, "_blank");
              }
            });

            // this.$confirm('Are you sure you want to enter the lecture?', 'Tips', {
            //     confirmButtonText: 'confirm',
            //     cancelButtonText: 'cancel',
            //     type: 'warning'
            // }).then(res=>{
            //     let url = this.$router.resolve({
            //         path:'VideoPlay',
            //         query:{
            //             videoTime:item.videoTime
            //         },
            //     })

            //     window.open(url.href,'_blank')
            // })
          } else {
            // console.log(222)
            this.$confirm(
              "Are you sure you want to enter the lecture?",
              "Tips",
              {
                confirmButtonText: "confirm",
                cancelButtonText: "cancel",
                type: "warning",
              }
            ).then((res) => {
              let url = this.$router.resolve({
                path: "/studentscreen",
                query: {
                  roomId: item.event_meeting_room_ids[0],
                },
              });
              window.open(url.href, "_blank");
            });
          }
        } else if (res.data.code === -1) {
          this.$message.warning("Time not right！");
        } else if (res.data.code === -2) {
          this.$message.warning("No room info");
        } else if (res.data.code === -3) {
          this.$message.warning("Have finished");
        }
      });
    },
    toExam(item) {
      console.log(11111, item);
      // checkTestLinkOpen(getUserId(),item.exam_info.name).then(res=>{
      //     if(res.data.code===0){
      //         this.$confirm('Are you sure you want to start the exam?', 'Tips', {
      //             confirmButtonText: 'confirm',
      //             cancelButtonText: 'cancel',
      //             type: 'warning'
      //         }).then(()=>{
      //             let url = this.$router.resolve({
      //                 path:'/test',
      //                 query:{
      //                     template_id:item.exam_info.test_link,
      //                     roomId:res.data.data
      //                 }
      //             })
      //             window.open(url.href,'_blank')
      //         })
      //     }else if(res.data.code===-1){
      //         this.$message.warning('Time not right!')
      //     }else if(res.data.code===-2){
      //         this.$message.warning('No room Info')
      //     }else if(res.data.code===-3){
      //         this.$message.warning('Have Finished')
      //     }else if(res.data.code===-4){
      //         this.$message.warning('Please enter the room in Proctor Pro APP')
      //     }
      // })
      this.goExam(item);
    },
    goExam(item) {
      var startTime = item.event_start_time;
      var temp1 = parseInt(startTime) * 1000;
      var currentTime = new Date().getTime();
      // console.log('temp1 :' + temp1)
      // console.log('currentTime :' + currentTime)
      //TODO:为了测试，去掉时间判断
      var test_event = item.name;
      if (item.type == "Formal") {
        if (temp1 - this.exam_entry_before_time > currentTime) {
          this.$message({
            message:
              "The test has not started. You can only enter 15 minutes before the start time",
            type: "warning",
          });
          return;
        }
        //考试开始15分钟，无法进入考场
        if (temp1 + this.exam_prohibit_time_when_start < currentTime) {
          this.$message({
            message:
              "The entry time is already over. You can not enter into the test room now.",
            type: "warning",
          });
          return;
        }
      }

      getExerciseTestTemplate(
        item.name,
        getProjectCode(),
        item.exam_info.type,
        test_event
      ).then((res) => {
        if (res.data.code == 0) {
          var result = res.data.data;
          var template_id = result._id.$id;
          this.doCheck(template_id, temp1, item.type, test_event);
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    doCheck(template_id, timeStr, event_type, test_event) {
      var student_id = getUserId();
      checkIsUploadTest(template_id, student_id).then((res) => {
        var result = res.data;
        // result= false
        if (result) {
          this.$alert(
            "Please note that you have already completed this test. No re-entry is allowed.",
            "Tip",
            {
              confirmButtonText: "OK",
              callback: () => {},
            }
          );
        } else {
          setTestEvent(test_event);

          //判断是否需要监考
          checkUserInvigilate(getProjectCode(), getUserId()).then((res) => {
            // console.log(1111,res)
            //true 需要监考
            if (res.data == true) {
              this.$router.push({
                path: "/ready/exam",
                query: {
                  template_id: template_id,
                  start_time: timeStr,
                  event_type: event_type,
                },
              });
            } else {
              this.$router.push({
                path: "/exam",
                query: {
                  template_id: template_id,
                  invigilateStatus: true,
                },
              });
            }
          });
        }
      });
    },

    showTip(item){

      if (item == 1){
        this.$alert(
            "Please note that you have already completed this test. No re-entry is allowed.",
            "Tip",
            {
              confirmButtonText: "OK",
              callback: () => {},
            }
          );
      }
      if (item == 2){
        this.$alert(
            "You have missed the entry time for this exam. For further assistance, please contact your instructor.",
            "Test Entry Closed",
            {
              confirmButtonText: "Confirm",
              callback: () => {},
            }
          );
      }


      
    },
    // formatSessionDate(time) {
    //   var time_1 = JSON.parse(time) * 1000;
    //   var date = new Date(time_1);
    //   return formatDate(date, "yyyy-MM-dd hh:mm");
    // },
    formateTime(data) {
      let item = new Date(data * 1000 -  8 * 3600 * 1000);
    //   let item = new Date(data * 1000);
    //   return item.toUTCString()
      let month = item.getMonth() + 1;
      return (
        item.getDate() +
        " " +
        this.formatMonth(month) +
        " " +
        item.getFullYear() +
        " " +
        this.formatValue(item.getHours()) +
        ":" +
        this.formatValue(item.getMinutes()) +
        ":" +
        this.formatValue(item.getSeconds()) 
      );
    //   return `${item.getMonth()+1}-${item.getDate()} ${item.getHours()}:${item.getMinutes()>9?item.getMinutes():'0'+item.getMinutes()}`;
    },
    formatMonth(month) {
      switch (month) {
        case 1:
          return "Jan";
        case 2:
          return "Feb";
        case 3:
          return "Mar";
        case 4:
          return "Apr";
        case 5:
          return "May";
        case 6:
          return "Jun";
        case 7:
          return "Jul";
        case 8:
          return "Aug";
        case 9:
          return "Sep";
        case 10:
          return "Oct";
        case 11:
          return "Nov";
        case 12:
          return "Dec";
      }
    },
    formatValue(value) {
      if (value > 9) {
        return value;
      }
      return "0" + value;
    },
    handleEventName(event) {
      let event_array = event.split(" ");
      return event_array[0];
    },
    handleIndex(index) {
      return (index % 4) + 1;
    },
    getFormatDuringTime(during) {
      var s = Math.floor(during / 1) % 60;
      during = Math.floor(during / 60);
      var i = during % 60;
      during = Math.floor(during / 60);
      var h = during;
      return h + ":" + i + ":" + s;
    },
    handleRestTime(item) {
      let totalTime = item.status.rest_time;
      item.countDownThread = window.setInterval(() => {
        this.$set(item, "countDownTimes", this.getFormatDuringTime(totalTime));
        totalTime--;
        if (totalTime < 0) {
          window.clearInterval(item.countDownThread);
          item.status.status = "进行中";
        }
        // console.log(this.event_rooms)
      }, 1000);
    },
  },
};
</script>

<style lang="less">
.listContainer {
  .dl {
    width: 84%;
    display: flex;
    align-items: center;
    font-family: Impact;
    justify-content: center;

    color: #072d76;
    font-size: 50px;
    margin-bottom: 20px;
  }
  .eventItem {
    width: 84%;
    height: 17vh;
    margin-bottom: 4vh;
    position: relative;
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
    .eventImg {
      position: absolute;
      top: 0;
      // z-index: 1;
      height: 100%;
      width: 100%;
      max-width: 100%;
      display: block;
    }
    .left {
      width: 60%;
      overflow: hidden;
      position: relative;
      .itemTitle {
        margin-top: 2vh;
        font-size: 2.4vh;
        font-family: Impact;
        font-weight: 400;
        padding-left: 30px;
        white-space: nowrap;
      }
      .itemName {
        font-size: 5vh;
        font-family: Impact;
        font-weight: 400;
        padding-left: 30px;
        white-space: nowrap;
      }
      .eventTime {
        background: #32b67a;
        height: 2.6vh;
        width: 100%;
        margin-top: 1vh;
        margin-left: 30px;
        color: white;
        font-size: 1.4vh;
        line-height: 2.6vh;
        font-family: Microsoft YaHei;
        font-weight: bold;
        .eventTimeContainer {
          // background-color: red;
          width: 100%;
          margin: 0 auto;
          display: flex;
          .eventTimeItem {
            width: 50%;
            margin-left: 10px;
            text-align: left;
          }
        }
      }
    }
    .right {
      width: 38%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .eventStatusContainer {
        width: 18vh;
        height: 50%;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .toContent {
          text-align: center;
          color: white;
          font-family: Microsoft YaHei;
          font-weight: bold;
          cursor: pointer;
          padding: 3vh;
        }
        .outContent {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          .txt {
            margin-top: 0.4vh;
            font-size: 1.4vh;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
          }
          .time {
            background: white;
            width: 80%;
            padding: 0.6vh 4px;
            font-family: Impact;
            font-weight: 400;
            font-size: 3vh;
            text-align: center;
            margin-bottom: 0.4vh;
          }
        }
      }
    }
  }
}
</style>